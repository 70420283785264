import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Bellhops from "../images/Bellhops.png"
import DataDrivenOptimization from "../images/DataDrivenOptimization.png"
import LeadQuality from "../images/LeadQuality.png"
import Macroair from "../images/Macroair-1.svg"
import MultiChannel from "../images/MultiChannel.png"
import ProvenTrackRecord from "../images/ProvenTrackRecord.png"
import Scalability from "../images/Scalability.png"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapefour from "../images/bannerElement/white-design-element-paidmarketing.png"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import CuttingEdgeTechnology from "../images/icons/PaidMarketing/Cutting-edge-technology.svg"
import OptimizedProcess from "../images/icons/PaidMarketing/Optimized-process.svg"
import ResearchBasedStrategy from "../images/icons/PaidMarketing/Research-based-strategy.svg"
import AMEX from "../images/logos/Paid Marketing/Group14.svg"
import Coveo from "../images/logos/Paid Marketing/Group30.svg"
import Cohere from "../images/logos/Paid Marketing/Group31.svg"
import ProudMoments from "../images/logos/Paid Marketing/Group37.svg"
import Fourth from "../images/logos/Paid Marketing/Group39.svg"
import partners from "../images/media-partners-non-hero.svg"
import platforms from "../images/platforms-img.svg"
import "../styles/404.css"

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  )
}
const PaidMarketing = () => {
  useEffect(() => {
    const items = document.querySelectorAll(".accordion button")

    function toggleAccordion() {
      const itemToggle = this.getAttribute("aria-expanded")

      if (itemToggle === "false") {
        for (let i = 0; i < items.length; i++) {
          items[i].setAttribute("aria-expanded", "false")
        }
        this.setAttribute("aria-expanded", "true")
      } else {
        // Check if the clicked accordion is already open, if yes, do nothing
        if (itemToggle === "true") {
          return
        }
        this.setAttribute("aria-expanded", "false")
      }
    }

    items.forEach(item => item.addEventListener("click", toggleAccordion))

    return () => {
      items.forEach(item => item.removeEventListener("click", toggleAccordion))
    }
  }, [])
  const [imageSrc, setImageSrc] = useState(ProvenTrackRecord)
  const [isLoading, setIsLoading] = useState(false)

  const changeImage = newImageSrc => {
    setIsLoading(true)
    setTimeout(() => {
      setImageSrc(newImageSrc)
      setIsLoading(false)
    }, 100)
  }

  useEffect(() => {
    setIsLoading(true)
    const image = new Image()
    image.src = imageSrc
    image.onload = () => {
      setIsLoading(false)
    }
  }, [imageSrc])

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Paid Marketing | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div class="paid-marketing demand-generation">
          <section id="Banner">
            <div class="container">
              <div class="banner-holder">
                <div class="images">
                  <div className="img-one image-shape">
                    <img src={Shapeone} alt="Image" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={Shapetwo} alt="Image" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={Shapethree} alt="Image" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={Shapefour} alt="Image" />
                  </div>
                </div>
                <div class="title">
                  <div class="banner-title">
                    <h1>
                      Amplify your reach <br />
                      with Paid Marketing
                    </h1>
                    <p>
                      Run strategic campaigns focused on conversions and ROI
                    </p>
                    <a className="button" href="/contact-us">
                      Get in touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PageIntro">
            <div class="container">
              <h2>Reignite your growth with powerful Paid Marketing</h2>
              <p>
                Unlock real results with our data-driven paid marketing
                approach. We help businesses like yours acquire more qualified
                leads, boost conversions, and accelerate revenue growth, all
                while managing costs effectively.
              </p>
            </div>
          </section>
          <section id="TextImageSection">
            <div class="container">
              <div class="text-image-holder">
                <div class="text-wrap">
                  <h2>Here’s what sets us apart</h2>
                  <div class="accordion">
                    <div class="accordion-item">
                      <button
                        id="accordion-button-1"
                        aria-expanded="true"
                        onClick={() => changeImage(ProvenTrackRecord)}
                      >
                        <i class="ptwo ptwo-arrow"></i>
                        <span class="accordion-title">Proven Track Record</span>
                      </button>
                      <div class="accordion-content">
                        <p>
                          15+ years of experience driving results for 200+ B2B
                          and B2C companies.
                        </p>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <button
                        id="accordion-button-2"
                        aria-expanded="false"
                        onClick={() => changeImage(DataDrivenOptimization)}
                      >
                        <i class="ptwo ptwo-arrow"></i>
                        <span class="accordion-title">
                          Data-driven Optimization
                        </span>
                      </button>
                      <div class="accordion-content">
                        <p>
                          We prioritize ROI and optimize campaigns for final
                          conversions, ensuring budget efficiency.
                        </p>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <button
                        id="accordion-button-3"
                        aria-expanded="false"
                        onClick={() => changeImage(MultiChannel)}
                      >
                        <i class="ptwo ptwo-arrow"></i>
                        <span class="accordion-title">
                          Multi-channel Expertise
                        </span>
                      </button>
                      <div class="accordion-content">
                        <p>
                          We seamlessly integrate paid search, display, social
                          ads, sponsored emails, and media buys to reach your
                          target audience.
                        </p>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <button
                        id="accordion-button-4"
                        aria-expanded="false"
                        onClick={() => changeImage(LeadQuality)}
                      >
                        <i class="ptwo ptwo-arrow"></i>
                        <span class="accordion-title">
                          Focus on Lead Quality
                        </span>
                      </button>
                      <div class="accordion-content">
                        <p>
                          We attract not just leads but qualified leads most
                          likely to convert into paying customers.
                        </p>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <button
                        id="accordion-button-5"
                        aria-expanded="false"
                        onClick={() => changeImage(Scalability)}
                      >
                        <i class="ptwo ptwo-arrow"></i>
                        <span class="accordion-title">Scalability for All</span>
                      </button>
                      <div class="accordion-content">
                        <p>
                          We cater to businesses of all sizes, from Fortune 500
                          companies to startups, helping them achieve their
                          unique growth goals.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="img-wrap">
                  {isLoading && <Loader />}
                  <div
                    className={`image-container ${isLoading ? "fade-in" : ""}`}
                  >
                    <img
                      alt="Copy write"
                      width="500"
                      height="500"
                      src={imageSrc}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="ImageDescColSection">
            <div class="container">
              <h2>Here is our winning formula</h2>
              <p>
                At the core of our success lies a winning formula. This potent
                combination enables us to deliver highly effective and
                cost-efficient paid marketing campaigns that drive exceptional
                results.
              </p>
              <div class="imgdesc-cardwrapper">
                <div class="image-desc-card">
                  <div class="img-wrap">
                    <img
                      alt="AI image"
                      width="100"
                      height="100"
                      src={ResearchBasedStrategy}
                    />
                  </div>
                  <div class="text-wrap">
                    <p>
                      <span>
                        Research-based Strategy <br />
                      </span>
                      Data-driven market insights fuel our approach.
                    </p>
                  </div>
                </div>
                <div class="image-desc-card">
                  <div class="img-wrap">
                    <img
                      alt="Proactive"
                      width="100"
                      height="100"
                      src={OptimizedProcess}
                    />
                  </div>
                  <div class="text-wrap">
                    <p>
                      <span>
                        Optimized Processes <br />
                      </span>
                      Efficient execution for cost-effective campaigns.
                    </p>
                  </div>
                </div>
                <div class="image-desc-card">
                  <div class="img-wrap">
                    <img
                      alt="Tech Expert"
                      width="100"
                      height="100"
                      src={CuttingEdgeTechnology}
                    />
                  </div>
                  <div class="text-wrap">
                    <p>
                      <span>
                        Cutting-edge Technology <br />
                      </span>
                      Advanced tools enable data-driven optimization.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="BussinessColSection">
            <div class="container">
              <h2>We cater to diverse industries</h2>
              <p class="businesscol-desc">
                Our Paid Marketing services drive customer acquisition across
                all paid channels - Search, Display, Social, Email, and Media
                Buys for B2C, B2B, B2SMB, E-commerce & SaaS businesses. With 15
                years of experience spanning 200+ companies, we expertly
                navigate strategies proven to accelerate demand and success in
                paid marketing for diverse industries.
              </p>
              {/* <div>
                <div class="bussinesscol-wrapper">
                  <div class="each-bussinesscol">
                    <div class="img-wrap">
                      <img alt="B2B" width="200" height="200" src={B2B} />
                      <p>B2B</p>
                    </div>
                  </div>
                  <div class="each-bussinesscol">
                    <div class="img-wrap">
                      <img alt="B2C" width="200" height="200" src={B2C} />
                      <p>B2C</p>
                    </div>
                  </div>
                  <div class="each-bussinesscol">
                    <div class="img-wrap">
                      <img alt="B2SMB" width="200" height="200" src={B2SMB} />
                      <p>B2SMB</p>
                    </div>
                  </div>
                  <div class="each-bussinesscol">
                    <div class="img-wrap">
                      <img
                        alt="Ecommerce"
                        width="200"
                        height="200"
                        src={Ecommerce}
                      />
                      <p>Ecommerce</p>
                    </div>
                  </div>
                  <div class="each-bussinesscol">
                    <div class="img-wrap">
                      <img alt="SaaS" width="200" height="200" src={Saas} />
                      <p>SaaS</p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </section>
          <section id="CaseStudy">
            <div class="sliderwrap">
              <div class="contentholder">
                <div class="container">
                  <div class="descp">
                    <p class="title">Case Study</p>
                    <div class="img-wrap">
                      <img src={Bellhops} alt="Bellhop Logo" />
                    </div>
                    <div>
                      <h2>
                        Bellhops decreased their CPL by 57% and and increased
                        their conversions 3.75x
                      </h2>
                    </div>
                    <div>
                      <p>
                        Position<sup>2</sup> partnered with Bellhops, a moving
                        company located in many cities across the country. The
                        biggest challenge they were facing was not being able to
                        scale non brand conversions. This case study discusses
                        Position<sup>2</sup>’s hyperlocal strategy to overcome
                        Bellhops’ challenges through our research and
                        optimization using technology.{" "}
                      </p>
                    </div>
                    <a
                      className="button"
                      href="https://www.position2.com/position2-bellhop-case-study.pdf"
                      target="_blank"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="ParallexSection" class="parallex1">
            <div class="holder">
              <div class="container">
                <div class="TextImgSection">
                  <div class="text-image-holder">
                    <div class="text-wrap">
                      <div class="animation-text">
                        <div>
                          <h3>Partners</h3>
                        </div>
                        <div>
                          <p>
                            We have premium partnerships with key ad platforms.
                            Be a part of our beta (testing) programs with Google
                            and other partners. Get access to industry specific
                            data and quick resolutions through teams dedicated
                            to your business.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="mobile-img">
                      <img
                        alt="Media Partners"
                        width="550"
                        height="550"
                        src={partners}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="ParallexSection" class="parallex2">
            <div class="holder">
              <div class="container">
                <div class="TextImgSection">
                  <div class="text-image-holder">
                    <div class="text-wrap">
                      <div class="animation-text">
                        <div>
                          <h3>Platforms</h3>
                        </div>
                        <div>
                          <p>
                            Reach your target audience via the smartest routes
                            with our expertise. We continuously explore all
                            popular ad platforms as well as the niche ones. And
                            manage your ad campaigns across the best fits.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="mobile-img">
                      <img
                        alt="Platforms"
                        width="550"
                        height="550"
                        src={platforms}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PartnerLogoSection">
            <div class="container">
              <div class="logo-wrap">
                <div class="each-logo">
                  <img src={Cohere} alt="Cohere" />
                </div>
                <div class="each-logo">
                  <img src={ProudMoments} alt="Proud Moments" />
                </div>
                <div class="each-logo">
                  <img src={Coveo} alt="Coveo" />
                </div>
                <div class="each-logo">
                  <img src={AMEX} alt="Amex" />
                </div>
                <div class="each-logo">
                  <img src={Macroair} alt="Macroair" />
                </div>
                <div class="each-logo">
                  <img src={Fourth} alt="Fourth" />
                </div>
              </div>
            </div>
          </section>
          <section id="contactus-section">
            <div class="container">
              <h2>
                Partner with us today and unlock the potential of paid
                marketing!
              </h2>
              <a className="button" href="/contact-us">
                Get in touch
              </a>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

PaidMarketing.propTypes = {
  siteTitle: PropTypes.string,
}

PaidMarketing.defaultProps = {
  siteTitle: ``,
}

export default PaidMarketing
